import React from "react";
import { IoWalletOutline } from "react-icons/io5";
import { MdOutlineMarkEmailRead } from "react-icons/md";
import { IoColorPaletteOutline } from "react-icons/io5";
import { MdOutlineComputer } from "react-icons/md";
import { PiMicroscope } from "react-icons/pi";
import { BsGlobe } from "react-icons/bs";
import { IoBusinessOutline } from "react-icons/io5";

const Services = () => {
  return (
    <>
      <section className="section service" id="services">
        <div className="container">
          <h2 className="h2 section-title underline">Our Specialization</h2>

          <ul className="service-list">
            <li>
              <div className="service-card">
                <div className="card-icon">
                  <ion-icon>
                    <MdOutlineMarkEmailRead />
                  </ion-icon>
                </div>

                <h3 className="h3 title">Letter heads</h3>

                <p className="text">
                  Our letterhead represents official communication for business,
                  legal, and personal correspondence.
                </p>

                <div
                  className="publish-date"
                  style={{
                    color: "#D7006C",
                    backgroundColor: "transparent",
                    padding: "9px 20px",
                    borderRadius: "3px",
                    cursor: "pointer",
                    transition:
                      "transform 0.3s, box-shadow 0.3s, border-color 0.3s",
                    border: "1px solid #D7006C",
                    textAlign: "center",
                    display: "inline-block",
                    textDecoration: "none",
                    margin: "5px",
                  }}
                  onMouseEnter={(e) => {
                    e.target.style.transform = "scale(1.05)";
                    e.target.style.borderColor = "#FF3385";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.transform = "scale(1)";
                    e.target.style.borderColor = "#D7006C";
                  }}
                >
                  <p class="" style={{ display: "flex", alignItems: "center" }}>
                    <IoWalletOutline style={{ marginRight: "3px" }} />
                    Price: sh. 1,000{" "}
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="service-card">
                <div className="card-icon">
                  {/* Logo design icon */}
                  <ion-icon>
                    <IoColorPaletteOutline />
                  </ion-icon>{" "}
                </div>

                <h3 className="h3 title">Logo Design</h3>

                <p className="text">
                  We craft unique and impactful logos that capture the essence
                  of your brand. Strong, memorable identity for your bs.
                </p>
                <div
                  className="publish-date"
                  style={{
                    color: "#D7006C",
                    backgroundColor: "transparent",
                    padding: "9px 20px",
                    borderRadius: "3px",
                    cursor: "pointer",
                    transition:
                      "transform 0.3s, box-shadow 0.3s, border-color 0.3s",
                    border: "1px solid #D7006C",
                    textAlign: "center",
                    display: "inline-block",
                    textDecoration: "none",
                    margin: "5px",
                  }}
                  onMouseEnter={(e) => {
                    e.target.style.transform = "scale(1.05)";
                    e.target.style.borderColor = "#FF3385";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.transform = "scale(1)";
                    e.target.style.borderColor = "#D7006C";
                  }}
                >
                  <p class="" style={{ display: "flex", alignItems: "center" }}>
                    <IoWalletOutline style={{ marginRight: "3px" }} />
                    Price: sh. 5,000{" "}
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="service-card">
                <div className="card-icon">
                  <ion-icon>
                    <MdOutlineComputer />
                  </ion-icon>
                </div>

                <h3 className="h3 title">Web Development</h3>

                <p className="text">
                  We build custom, scalable, and secure websites using the
                  latest technologies to enhance user experience.
                </p>

                <div
                  className="publish-date"
                  style={{
                    color: "#D7006C",
                    backgroundColor: "transparent",
                    padding: "9px 20px",
                    borderRadius: "3px",
                    cursor: "pointer",
                    transition:
                      "transform 0.3s, box-shadow 0.3s, border-color 0.3s",
                    border: "1px solid #D7006C",
                    textAlign: "center",
                    display: "inline-block",
                    textDecoration: "none",
                    margin: "5px",
                  }}
                  onMouseEnter={(e) => {
                    e.target.style.transform = "scale(1.05)";
                    e.target.style.borderColor = "#FF3385";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.transform = "scale(1)";
                    e.target.style.borderColor = "#D7006C";
                  }}
                >
                  <p class="" style={{ display: "flex", alignItems: "center" }}>
                    <IoWalletOutline style={{ marginRight: "3px" }} />
                    Price: sh. 15,000 to 80,000{" "}
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="service-card">
                <div className="card-icon">
                  <ion-icon>
                    <PiMicroscope />
                  </ion-icon>
                </div>

                <h3 className="h3 title">Strategy & Research</h3>

                <p className="text">
                  We analyze market trends and customer needs to create
                  strategic plans that drive growth & competitiveness.
                </p>

                <div
                  className="publish-date"
                  style={{
                    color: "#D7006C",
                    backgroundColor: "transparent",
                    padding: "9px 20px",
                    borderRadius: "3px",
                    cursor: "pointer",
                    transition:
                      "transform 0.3s, box-shadow 0.3s, border-color 0.3s",
                    border: "1px solid #D7006C",
                    textAlign: "center",
                    display: "inline-block",
                    textDecoration: "none",
                    margin: "5px",
                  }}
                  onMouseEnter={(e) => {
                    e.target.style.transform = "scale(1.05)";
                    e.target.style.borderColor = "#FF3385";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.transform = "scale(1)";
                    e.target.style.borderColor = "#D7006C";
                  }}
                >
                  <p class="" style={{ display: "flex", alignItems: "center" }}>
                    <IoWalletOutline style={{ marginRight: "3px" }} />
                    Price: sh. 30,000{" "}
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="service-card">
                <div className="card-icon">
                  <ion-icon>
                    <BsGlobe />
                  </ion-icon>
                </div>

                <h3 className="h3 title">Web Solutions</h3>

                <p className="text">
                  We offer tailored web solutions, including custom applications
                  and e-commerce platforms, to meet your business needs.
                </p>

                <div
                  className="publish-date"
                  style={{
                    color: "#D7006C",
                    backgroundColor: "transparent",
                    padding: "9px 20px",
                    borderRadius: "3px",
                    cursor: "pointer",
                    transition:
                      "transform 0.3s, box-shadow 0.3s, border-color 0.3s",
                    border: "1px solid #D7006C",
                    textAlign: "center",
                    display: "inline-block",
                    textDecoration: "none",
                    margin: "5px",
                  }}
                  onMouseEnter={(e) => {
                    e.target.style.transform = "scale(1.05)";
                    e.target.style.borderColor = "#FF3385";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.transform = "scale(1)";
                    e.target.style.borderColor = "#D7006C";
                  }}
                >
                  <p class="" style={{ display: "flex", alignItems: "center" }}>
                    <IoWalletOutline style={{ marginRight: "3px" }} />
                    Price: sh. 100,000{" "}
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="service-card">
                <div className="card-icon">
                  {/* Changed icon to reflect consultancy */}
                  <ion-icon>
                    <IoBusinessOutline />
                  </ion-icon>
                </div>

                <h3 className="h3 title">Website Consultancy</h3>

                <p className="text">
                  We offer expert advice on web design, development, and
                  optimization to help you enhance your online presence.
                </p>

                <div
                  className="publish-date"
                  style={{
                    color: "#D7006C",
                    backgroundColor: "transparent",
                    padding: "9px 20px",
                    borderRadius: "3px",
                    cursor: "pointer",
                    transition:
                      "transform 0.3s, box-shadow 0.3s, border-color 0.3s",
                    border: "1px solid #D7006C",
                    textAlign: "center",
                    display: "inline-block",
                    textDecoration: "none",
                    margin: "5px",
                  }}
                  onMouseEnter={(e) => {
                    e.target.style.transform = "scale(1.05)";
                    e.target.style.borderColor = "#FF3385";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.transform = "scale(1)";
                    e.target.style.borderColor = "#D7006C";
                  }}
                >
                  <p class="" style={{ display: "flex", alignItems: "center" }}>
                    <IoWalletOutline style={{ marginRight: "3px" }} />
                    Price: sh. 2,000 per hr
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default Services;
