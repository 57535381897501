import project1 from "../images/ninetyone2.png";
import project2 from "../images/resume.png";
import project3 from "../images/baterry.png";
import project4 from "../images/cactus.png";
import project5 from "../images/christmas.png";
import project6 from "../images/headphones.png";
import project7 from "../images/signin-register.png";
import project8 from "../images/movies.png";
import project9 from "../images/plant.png";
import project10 from "../images/delivery.png";
import project11 from "../images/comfy.png";
import project12 from "../images/angile-logistics.png";
import project13 from "../images/register-login.png";
import project14 from "../images/repairshop.png";
import project15 from "../images/sheyskitchen.png";
import project16 from "../images/airbnb.png";
import project17 from "../images/donations.png";
import project18 from "../images/logistics.png";
import project19 from "../images/clothing.png";
import project20 from "../images/brain-wave.png";
import project21 from "../images/real-estate.png";
import project22 from "../images/car-dealers.png";
import project23 from "../images/clement.png";
import project24 from "../images/learning.png";
import project25 from "../images/furniture.png";
import project26 from "../images/car-dealers-2.png";
import project28 from "../images/hotel.png";
import project29 from "../images/job-hunt.png";
import project30 from "../images/healthcare.png";
import project31 from "../images/general-company.png";
import project32 from "../images/gym.png";
import project33 from "../images/simple-furniture.png";
import project34 from "../images/beauty.png";
import project35 from "../images/marketing.png";
import project36 from "../images/future-lenders.png";
import project37 from "../images/barber.png";
import project38 from "../images/dentist.png";
import project39 from "../images/prodcast.png";
import project40 from "../images/nfts.png";
import project41 from "../images/house-hant.png";
import project42 from "../images/crypto.png";
import project43 from "../images/mechanics2.png";
import project44 from "../images/real-estate.png";
import project45 from "../images/creatives.png";
import project46 from "../images/pets.png";
import project47 from "../images/sneakers-ecom.png";
import project48 from "../images/sneakers2.png";
import project49 from "../images/doctor.png";
import project50 from "../images/furnitue2.png";
import project51 from "../images/clothing-ecommerce.png";
import project53 from "../images/education.png";
import project54 from "../images/3d-designs.png";
import project55 from "../images/books.png";
import project56 from "../images/pets-two.png";
import project57 from "../images/digital-products.png";
import project58 from "../images/fly.png";
import project59 from "../images/books-two.png";
import project60 from "../images/food.png";
import project61 from "../images/nike.png";
import project62 from "../images/koffee.png";
import project63 from "../images/bike.png";
import project64 from "../images/food-delivery.png";
import project65 from "../images/hommade.png";
import project66 from "../images/edu-search.png";
import project67 from "../images/designers.png";
import project69 from "../images/pricing-page.png";
import project70 from "../images/tech-web.png";
import project71 from "../images/food-seller.png";
import project72 from "../images/landing-page.png";
import project73 from "../images/furniture-simple.png";
import project74 from "../images/holloween.png";
import project76 from "../images/big-data.png";
import project77 from "../images/insurance.png";
import project78 from "../images/ecom-html.png";
import project79 from "../images/car-rentals.png";
import project80 from "../images/single-product.png";
import project81 from "../images/seo.png";
import project82 from "../images/hosting.png";
import project83 from "../images/start-up.png";
import project84 from "../images/financial advisor.png";
import project85 from "../images/video-marketing.png";
import project86 from "../images/organic-ecommerce.png";
import project87 from "../images/education-scolar.png";
import project88 from "../images/ai.png";
import project91 from "../images/music-player.png";
import project92 from "../images/personal-portifolio-blog.png";
import project93 from "../images/education-with-backend.png";
import project94 from "../images/prod-cast-web.png";
import project95 from "../images/movies-web.png";
import project96 from "../images/threed-edu.png";
import project97 from "../images/babycare.png";
import project99 from "../images/visa-app.png";
import project100 from "../images/testing-web.png";
import project102 from "../images/books-store.png";
import project103 from "../images/uiux.png";
import project104 from "../images/login-register.png";
import project105 from "../images/blogs.png";
import project1001 from "../images/construction-company.png";
import project106 from "../images/ecommerce-nextjs.png";
import project107 from "../images/vite-dashboard.png";
import project108 from "../images/property-sale.png";
import project109 from "../images/payment-intergration.png";

const projects = [
  {
    image: project1,
    name: "Ninetyone subsidiary Website",
    desc: "Single Vender E-commerce business, a subsidiary of ninetyone.co.ke.",
    date: "26 Dec 2020",
    link: "https://ninetyone-subsidiary-website.vercel.app",
    reponame: "ninetyone-subsidiary-website",
    price: 6000,
    languages: ["Reactjs", "Nodejs"],
  },
  {
    image: project2,
    name: "Personal Portifolio Website",
    desc: "My job is to build your website so that it is functional and user-friendly but at the same time attractive.",
    date: "31 Jan 2024",
    link: "https://personal-portifolio-website-eight.vercel.app",
    reponame: "personal-portifolio-website",
    price: 1000,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project3,
    name: "Battery Status Website",
    desc: "See what is your battery %",
    date: "28 Dec 2020",
    link: "https://battery-status-website.vercel.app",
    reponame: "battery-status-website",
    price: 200,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project4,
    name: "Cactus Plant Website",
    desc: "Ornamental Plants For Your Home",
    date: "31 Dec 2020",
    link: "https://cactus-plant-website.vercel.app",
    reponame: "cactus-plant-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project5,
    name: "Merry Christmas Website",
    desc: "Merry Christmas and Happy New Year!",
    date: "31 Dec 2020",
    link: "https://christmas-website-lime.vercel.app",
    reponame: "christmas-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project6,
    name: "HeadPhones Website",
    desc: "With a comfortable and adaptable case so that you can store it whenever you want, and keep your durability forever.",
    date: "31 Jan 2021",
    link: "https://headphones-website-omega.vercel.app",
    reponame: "headphones-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project7,
    name: "Login Register website",
    desc: "Awesome Login Register website",
    date: "1 Feb 2021",
    link: "https://login-register-website-nu.vercel.app",
    reponame: "login-register-website",
    price: 300,
    languages: ["Html", "Css", "Javascript"],
  },

  {
    image: project8,
    name: "Movies Website",
    desc: "Responsive movies website",
    date: "3 Feb 2021",
    link: "https://movies-front-website.vercel.app",
    reponame: "movies-front-website",
    price: 300,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project9,
    name: "Plants Website",
    desc: "Plants will make your life better",
    date: "4 Feb 2021",
    link: "https://plantex-plants-website.vercel.app",
    reponame: "plantex-plants-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project10,
    name: "Goods Delivery (Logistics) website",
    desc: "Order Products Faster Easier",
    date: "10 Feb 2021",
    link: "https://goods-delivery-website.vercel.app",
    reponame: "goods-delivery-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project11,
    name: "Comfy Funiture Shop Website",
    desc: "A single vendor funiture shop fully funvtional and implemented with the admin dashboard. User friendly and smart UI design.",
    date: "13 Feb 2021",
    link: "https://comfy-funiture-shop-website.vercel.app",
    reponame: "comfy-funiture-shop-website",
    price: 5000,
    languages: ["Reactjs", "Nodejs"],
  },
  {
    image: project12,
    name: "Angile Logistics Website",
    desc: "A single vendor funiture shop fully funvtional and implemented with the admin dashboard. User friendly and smart UI design.",
    date: "13 Feb 2021",
    link: "https://angile-logistics-website.vercel.app",
    reponame: "angile-logistics-website",
    price: 5000,
    languages: ["Reactjs", "Nodejs"],
  },
  {
    image: project13,
    name: "Register Login website",
    desc: "Awesome Login Register website",
    date: "1 Feb 2021",
    link: "https://register-login-website.vercel.app",
    reponame: "register-login-website",
    price: 300,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project14,
    name: "Tech Repair Services Website",
    desc: "Best Computer Repair Service at your own site. One click Solution to all Computer Problems. Doorstep Service within 90 mins.",
    date: "28 Feb 2021",
    link: "https://tech-repair-services-website.vercel.app",
    reponame: "tech-repair-services-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project15,
    name: "Foody Kitchen Shop Website",
    desc: " Get ready for a taste sensation! Explore our delicious menu and treat yourself to culinary delights crafted just for you.",
    date: "28 Feb 2021",
    link: "https://foody-kitchen-website.vercel.app",
    reponame: "foody-kitchen-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project16,
    name: "Air BnB Three Dolts Cloned website",
    desc: "New Hosts get one-tap access to specially trained Community Support agents who can help with everything from account issues to billing support.",
    date: "2 April 2021",
    link: "https://airbnb-threed-website.vercel.app",
    reponame: "airbnb-threed-website",
    price: 2000,
    languages: ["Reactjs", "Nodejs"],
  },
  {
    image: project17,
    name: "Donations Website",
    desc: "Make a difference today: donate to MAB CORP and help improve lives.",
    date: "15 April 2021",
    link: "https://donations-website.vercel.app",
    reponame: "donations-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project18,
    name: "Logistics Business website",
    desc: "We are committed to offering safe, fast transport services adapted to the specific needs of each customer.",
    date: "25 April 2021",
    link: "https://logistics-threed-website.vercel.app",
    reponame: "logistics-threed-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project19,
    name: "Clothing Ecommerce Business Website",
    desc: "We are majoring in women party dress, skirts, tops, suit and jumpsuits for may years. 100% good quality guarantee at a reasonable price.",
    date: "30 April 2021",
    link: "https://clothing-business-ecommerce-website.vercel.app",
    reponame: "clothing-business-ecommerce-website",
    price: 4000,
    languages: ["Reactjs", "Nodejs"],
  },
  {
    image: project20,
    name: "Brain Waves website",
    desc: "Explore the Possibilities of AI Chatting with Brainwave",
    date: "2 June 2021",
    link: "https://brainwave-landing-website.vercel.app",
    reponame: "brainwave-landing-website",
    price: 50,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project21,
    name: "Real Estate website (Landing Page)",
    desc: "Discover Most Suitable Property",
    date: "16 Aug 2021",
    link: "https://real-estate-front-website.vercel.app",
    reponame: "real-estate-front-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project22,
    name: "Car Dealers website",
    desc: "Most dynamic car Dealers website- super ui",
    date: "26 April 2022",
    link: "https://car-dealer-seller-website.vercel.app",
    reponame: "car-dealer-seller-website",
    price: 4000,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project23,
    name: "Personal Portifolio Website",
    desc: "Welcome to Clement's Empire, a leading mechanical engineering firm dedicated to providing innovative solutions and exceptional service.",
    date: "11 May 2022",
    link: "https://personal-portifolio-clement-website.vercel.app",
    reponame: "personal-portifolio-clement-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project24,
    name: "Education e-Learning website",
    desc: "The beautiful thing about learning is that nobody can take it away from you",
    date: "8 June 2022",
    link: "https://e-learning-education-website.vercel.app",
    reponame: "e-learning-education-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project25,
    name: "Mebl Furniture Shop website",
    desc: "We have furniture you want to buy!",
    date: "27 June 2022",
    link: "https://mebl-furniture-shop-website.vercel.app",
    reponame: "mebl-furniture-shop-website",
    price: 1000,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project26,
    name: "Sams Car Seller website",
    desc: "Find your car in sams dealers",
    date: "8 July 2022",
    link: "https://sams-car-seller-website.vercel.app",
    reponame: "sams-car-seller-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project28,
    name: "Hotel website",
    desc: "Make Yourself At Home In Our Hotel.",
    date: "16 July 2022",
    link: "https://hotel-threed-website.vercel.app",
    reponame: "hotel-threed-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project29,
    name: "Job Hunt website",
    desc: "Search, Apply & Get Your Dream Job.",
    date: "1 Aug 2022",
    link: "https://job-hunt-website-seven.vercel.app",
    reponame: "job-hunt-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project30,
    name: "Healthcare website",
    desc: "Providing an Exceptional Patient Experience",
    date: "30 Aug 2022",
    link: "https://health-care-website-ten.vercel.app",
    reponame: "health-care-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },

  {
    image: project31,
    name: "General Company website - Adex",
    desc: "Crafting project specific solutions with expertise.",
    date: "1 Sep 2022",
    link: "https://general-company-website.vercel.app",
    reponame: "general-company-website",
    price: 400,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project32,
    name: "Gym website",
    desc: "Work Hard To Get Better Life",
    date: "8 Sep 2022",
    link: "https://gym-threed-website.vercel.app",
    reponame: "gym-threed-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project33,
    name: "Woodex Furniture Shop Website",
    desc: "One Page Furniture Website",
    date: "22 Sep 2022",
    link: "https://woodex-furniture-shop-website.vercel.app",
    reponame: "woodex-furniture-shop-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project34,
    name: "Beaty Shop  website",
    desc: "Reveal The Beauty of Skin",
    date: "29 Sep 2022",
    link: "https://beauty-shop-website.vercel.app",
    reponame: "beauty-shop-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project35,
    name: "Marketing website",
    desc: "We are available for marketing",
    date: "3 Oct 2022",
    link: "https://marketing-threed-website.vercel.app",
    reponame: "marketing-threed-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project36,
    name: "Techx Business website",
    desc: "Make Chat Easy For Today's Digital Customers",
    date: "9 Oct 2022",
    link: "https://techx-threed-website.vercel.app",
    reponame: "techx-threed-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project37,
    name: "Barber website",
    desc: "BARBERS & HAIR CUTTING",
    date: "19 Oct 2022",
    link: "https://barber-threed-website.vercel.app",
    reponame: "barber-threed-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project38,
    name: "Dentist website",
    desc: "We Are Best Dental Service",
    date: "21 Oct 2022",
    link: "https://dentist-doctor-website.vercel.app",
    reponame: "dentist-doctor-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project39,
    name: "Prodcast website",
    desc: "Prodcast responsive web",
    date: "29 Oct 2022",
    link: "https://prodcast-website.vercel.app",
    reponame: "prodcast-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project40,
    name: "NFTs BID website",
    desc: "Discover, collect, and sell extraordinary NFTs",
    date: "5 Nov 2022",
    link: "https://nftc-threed-website.vercel.app",
    reponame: "nftc-threed-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project41,
    name: "House Hant website",
    desc: "We will help you find your Wonderful home",
    date: "16 Feb 2023",
    link: "https://house-hunt-rentals-website.vercel.app",
    reponame: "house-hunt-rentals-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project42,
    name: "Crypo website",
    desc: "Buy & Sell Digital Assets In The Cryptex",
    date: "8 Aug 2023",
    link: "https://crpto-exchange-website.vercel.app",
    reponame: "crpto-exchange-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project43,
    name: "Mechanics2 website",
    desc: "AUTO MAINTENANCE & REPAIR SERVICE",
    date: "12 Dec 2023",
    link: "https://mechanics-business-website.vercel.app/",
    reponame: "mechanics-business-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project44,
    name: "Real Estate website (Full)",
    desc: "Discover Most Suitable Property",
    date: "16 Aug 2021",
    link: "https://real-estate-full-website-eta.vercel.app",
    reponame: "real-estate-full-website",
    price: 5000,
    languages: ["Reactjs", "Nodejs"],
  },
  {
    image: project45,
    name: "Creatives website",
    desc: "Creative Landing Page",
    date: "31 March 2024",
    link: "https://creatives-threed-website.vercel.app",
    reponame: "creatives-threed-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project46,
    name: "Pets website",
    desc: "We Care Your Pets",
    date: "11 May 2024",
    link: "https://pets-threed-website.vercel.app",
    reponame: "pets-threed-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project47,
    name: "Sneakers Ecommerce website",
    desc: " Competently expedite alternative benefits whereas leading-edge catalysts for change. Globally leverage existing an expanded  array of leadership.",
    date: "12 May 2024",
    link: "https://sneakers-ecom-nine.vercel.app",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project48,
    name: "Sneakers Ecommerce website",
    desc: "Competently expedite alternative benefits whereas leading-edge catalysts for change. Globally leverage existing an expanded array of leadership.",
    date: "13 May 2024",
    link: "https://sneakers-ecommerce-website-zeta.vercel.app",
    reponame: "sneakers-ecommerce-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project49,
    name: "Doctor Website",
    desc: "Find Nearest Doctor. Experienced Workers",
    date: "14 May 2024",
    link: "https://doctor-appointment-website-omega.vercel.app",
    reponame: "doctor-appointment-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project50,
    name: "Furniture Website",
    desc: "Make Your Home Modern Design.",
    date: "15 May 2024",
    link: "https://furniture-flame-ten.vercel.app",
    reponame: "furniture",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project51,
    name: "Casmart Clothing ecommerce Website",
    desc: "Unrivalled Fashion House",
    date: "16 May 2024",
    link: "https://casmart-clothing-website.vercel.app",
    reponame: "casmart-clothing-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project53,
    name: "Education Website",
    desc: "The Best Program to Enroll for Exchange",
    date: "18 May 2024",
    link: "https://education-threedolts-website.vercel.app",
    reponame: "education-threedolts-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project54,
    name: "3d-designs Website",
    desc: "Building Digital Product, Brand and Experience",
    date: "20 May 2024",
    link: "https://pixology-threedolts-website.vercel.app",
    reponame: "pixology-threedolts-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project55,
    name: "books Website",
    desc: "Read More And Make Success The Result Of Perfection.",
    date: "23 May 2024",
    link: "https://books-store-website-mu.vercel.app",
    reponame: "books-store-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project56,
    name: "Pets Website",
    desc: "High Quality Pet Food Sale up to 40% off today",
    date: "26 May 2024",
    link: "https://pets-store-threedolts-website.vercel.app",
    reponame: "pets-store-threedolts-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project57,
    name: "Digital-Products Website",
    desc: "Digital product design agency Create live segments and target the right people for messages based on their behaviors.",
    date: "29 May 2024",
    link: "https://digital-products-store-website.vercel.app",
    reponame: "digital-products-store-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project58,
    name: "Fly Jet Website",
    desc: "LUXURY JET FLIGHTS",
    date: "1 June 2024",
    link: "https://fly-jet-website.vercel.app",
    reponame: "fly-jet-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project59,
    name: "Books Website",
    desc: "Get Your New Book Collections There are many variations of passages",
    date: "2 June 2024",
    link: "https://books-store-selling-website.vercel.app",
    reponame: "books-store-selling-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project60,
    name: "Foods Website",
    desc: " Eat Sleep And Supper delicious Burger in town! Food is any substance consumed to provide nutritional support for an organism.",
    date: "4 June 2024",
    link: "https://food-seller-website.vercel.app",
    reponame: "food-seller-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project61,
    name: "Nike-product description website",
    desc: "Fall Limited Edition Sneakers",
    date: "6 June 2024",
    link: "https://product-descriprion-threed-website.vercel.app",
    reponame: "product-descriprion-threed-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project62,
    name: "Coffee Shop website",
    desc: "Greate coffee, good vibes",
    date: "7 June 2024",
    link: "https://koffee-mug-website.vercel.app",
    reponame: "koffee-mug-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project63,
    name: "Bike Rent website",
    desc: "Book Your New Eco-Friendly Ride.",
    date: "8 June 2024",
    link: "https://bike-rentals-business-website.vercel.app",
    reponame: "bike-rentals-business-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project64,
    name: "Food Delivery website",
    desc: "The Best Restaurants In Your Home",
    date: "5 July 2024",
    link: "https://food-delivery-business-threed-website.vercel.app",
    reponame: "food-delivery-business-threed-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project65,
    name: "Home Made website",
    desc: "We Make Top Quality Handmade Products",
    date: "10 July 2024",
    link: "https://hommade-items-website.vercel.app",
    reponame: "hommade-items-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project66,
    name: "Education website",
    desc: "Education Is About Academic Excellence - nice search bar",
    date: "13 July 2024",
    link: "https://education-courses-threed-website.vercel.app",
    reponame: "education-courses-threed-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project67,
    name: "Designer website",
    desc: "WE ARE CREATIVE DESIGN AGENCY - CREATIVE AGENCY",
    date: "15 July 2024",
    link: "https://designers-threed-website.vercel.app",
    reponame: "designers-threed-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project69,
    name: "Pricing Page website",
    desc: "Fancy Pricing Page",
    date: "17 July 2024",
    link: "https://pricing-page-threed-website.vercel.app",
    reponame: "pricing-page-threed-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project70,
    name: "Tech website ",
    desc: "Secure IT solutions for a more secure environment",
    date: "18 July 2024",
    link: "https://tecth-webpage-threed-website.vercel.app",
    reponame: "tecth-webpage-threed-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project71,
    name: "Food website",
    desc: "We do not cook, we create your emotions!",
    date: "19 July 2024",
    link: "https://food-seller-threed-website.vercel.app",
    reponame: "food-seller-threed-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project72,
    name: "Nxtjs - Landing Page website",
    desc: "Elevate Your Android Experience with CherishOS",
    date: "19 July 2024",
    link: "https://nextjs-landing-page-website.vercel.app",
    reponame: "nextjs-landing-page-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project73,
    name: "Furniture Shop website",
    desc: "We Help You Make Modern Interior",
    date: "19 July 2024",
    link: "https://furniture-shop-page-website.vercel.app",
    reponame: "furniture-shop-page-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project74,
    name: "Halloween website",
    desc: "Halloween website - bring back my caddy",
    date: "20 July 2024",
    link: "https://halloween-threed-website.vercel.app",
    reponame: "halloween-threed-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project76,
    name: "Dovi Big Data Analytics website",
    desc: "We Provide Big Data Analytics And Techniques",
    date: "20 July 2024",
    link: "https://dovi-big-data-website.vercel.app",
    reponame: "dovi-big-data-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project77,
    name: "Insurance Co website",
    desc: "Life insurance makes your life happier",
    date: "20 July 2024",
    link: "https://insurance-web-threedolts-website.vercel.app",
    reponame: "insurance-threedolts-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project78,
    name: "Ecommerce Co website",
    desc: "Ecommerce shop in html",
    date: "24 July 2024",
    link: "https://multi-shops-ecommerce-threedolts-website.vercel.app",
    reponame: "multi-shops-ecommerce-website",
    price: 800,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project79,
    name: "Car Rentals website",
    desc: "Get 15% off your rental Plan your trip now",
    date: "24 July 2024",
    link: "https://car-rentals-threedolts-website.vercel.app",
    reponame: "car-rentals-threedolts-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project80,
    name: "Single Product website",
    desc: "Hair Shampoo For Healthy Hair",
    date: "24 July 2024",
    link: "https://single-product-co-website.vercel.app",
    reponame: "single-product-co-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project81,
    name: "SEO website",
    desc: "All in one SEO tool need to grow your business rapidly",
    date: "25 July 2024",
    link: "https://seo-company-website.vercel.app",
    reponame: "seo-company-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project82,
    name: "Hosting website",
    desc: "Shared Hosting",
    date: "25 July 2024",
    link: "https://web-hosting-threedolts-company-website.vercel.app",
    reponame: "web-hosting-company-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project83,
    name: "StartUp website",
    desc: "Creative Services For Brands Grow",
    date: "26 July 2024",
    link: "https://start-up-company-website.vercel.app",
    reponame: "start-up-company-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project84,
    name: "Financial Advisor website",
    desc: "True Financial Support For You",
    date: "27 July 2024",
    link: "https://financial-advisor-business-threedolts-website.vercel.app",
    reponame: "financial-advisor-business-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project85,
    name: "Video - Marketing website",
    desc: "The Design Thinking Superpowers. Tools tutorials, design and innovation experts, all in one place! The most intuitive way to imagine your next user experience.",
    date: "9 August 2024",
    link: "https://marketing-business-video-threedolts-website.vercel.app",
    reponame: "marketing-business-video-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project86,
    name: "Orgnic Ecommerce website",
    desc: "FRUIT FRESH Vegetable 100% Organic Free Pickup and Delivery Available",
    date: "9 August 2024",
    link: "https://organic-eccomerce-threedolts-website.vercel.app",
    reponame: "organic-eccomerce-threedolts-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project87,
    name: "Education Scolar website",
    desc: "Online Learning helps you save the time and resources",
    date: "9 August 2024",
    link: "https://education-scolar-threedolts-website.vercel.app",
    reponame: "education-scolar-threedolts-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project88,
    name: "AI website",
    desc: "Artificial Intelligence for Your Business",
    date: "13 August 2024",
    link: "https://ai-web-threedolts-website.vercel.app",
    reponame: "ai-threedolts-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project91,
    name: "Music Player Website",
    desc: "Happy Moments (Master)",
    date: "27 Sebtember 2024",
    link: "https://music-player-threedolts-website.vercel.app",
    reponame: "music-player-threedolts-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project92,
    name: "Personal Portifolio Blog Website",
    desc: "I use animation as a third dimension by which to simplify experiences and kuiding thro each and every interaction. I’m not adding motion just to spruce things up, but doing it in ways that.",
    date: "27 Sebtember 2024",
    link: "https://personal-portifolio-blog-website.vercel.app",
    reponame: "personal-portifolio-blog-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project93,
    name: "Education Website (has backend)",
    desc: "Education Is About Academic Excellence",
    date: "27 Sebtember 2024",
    link: "https://education-courses-threedolts-with-backed-website.vercel.app",
    reponame: "education-courses-threedolts-with-backed-website",
    price: 800,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project94,
    name: "Prod Cast Website",
    desc: "The most powerful audio streamer for devices",
    date: "27 Sebtember 2024",
    link: "https://prodcast-landing-website.vercel.app",
    reponame: "prodcast-landing-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project95,
    name: "Movies Website",
    desc: "Welcome. Millions of movies, TV shows and people to discover. Explore now.",
    date: "27 Sebtember 2024",
    link: "https://movies-streaming-threedolts-website.vercel.app",
    reponame: "movies-streaming-threedolts-website",
    price: 800,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project96,
    name: "Three Dolts Education- LMS",
    desc: "Empower Your Future with Valuable Skills",
    date: "30 Sebtember 2024",
    link: "https://threed-education-lms-website.vercel.app",
    reponame: "threed-education-lms-website",
    price: 4000,
    languages: ["Reactjs", "Nodejs"],
  },
  {
    image: project97,
    name: "Babycare daycare website",
    desc: "The Best Play Area For Your Kids",
    date: "16 October 2024",
    link: "https://baby-car-threedolts-website.vercel.app",
    reponame: "baby-car-threedolts-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project99,
    name: "Visa Application website",
    desc: "Immigration Process Starts Here!",
    date: "18 October 2024",
    link: "https://visa-application-travisa-website.vercel.app",
    reponame: "visa-application-travisa-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project100,
    name: "Testing Three Dolts",
    desc: "Process Starts Here!",
    date: "7 November 2024",
    link: "https://testing-three-dolts-website.vercel.app",
    reponame: "testing-three-dolts-website",
    price: 10,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project1001,
    name: "Construction Company Website",
    desc: "Our experienced team ensures each project is completed on time, on budget, and to the highest standards of craftsmanship",
    date: "11 November 2024",
    link: "https://construction-company-website-ten.vercel.app",
    reponame: "construction-company-website",
    price: 500,
    languages: ["Html", "Css", "Javascript"],
  },
  {
    image: project102,
    name: "Books Store Website",
    desc: "Hello, welcomes here to learn something new everyday!!!",
    date: "11 November 2024",
    link: "https://book-store-threedolts-website.vercel.app",
    reponame: "book-store-threedolts-website",
    price: 700,
    languages: ["Reactjs", "Nodejs", "Vite"],
  },
  {
    image: project103,
    name: "Personal Portifolio Website (ux/ui)",
    desc: "Get yourself a persoal website and be professional!!!",
    date: "25 November 2024",
    link: "https://personal-portifolio-ux-ui-designer.vercel.app",
    reponame: "personal-portifolio-ux-ui-designer",
    price: 500,
    languages: ["Html", "Css", "Jss"],
  },
  {
    image: project104,
    name: "Login Register Form",
    desc: "Responsive Login Form website",
    date: "25 November 2024",
    link: "https://login-register-amine-page-website-q9wn.vercel.app",
    reponame: "login-register-amine-page-website",
    price: 500,
    languages: ["Html", "Css", "Jss"],
  },
  {
    image: project103,
    name: "Login Register Form",
    desc: "Responsive Darshbard website",
    date: "25 November 2024",
    link: "https://admin-dashboard-responsive-website-w81q.vercel.app",
    reponame: "admin-dashboard-responsive-website",
    price: 500,
    languages: ["Html", "Css", "Jss"],
  },
  {
    image: project105,
    name: "Blogs Website - Three Dolts",
    desc: "Responsive clean UI blogs websites",
    date: "30 January 2025",
    link: "https://three-dolts-blogs-website.vercel.app",
    reponame: "three-dolts-blogs-website",
    price: 5000,
    languages: ["React", "Node", "Vite", "Tailwindcss"],
  },
  {
    image: project106,
    name: "Nextjs Static Ecommerce, Gargets, Computers Website",
    desc: "Supper responsive Ecommerce shop website. SEO optimised",
    date: "6 March 2025",
    link: "https://static-eccommerce-nextjs.vercel.app",
    reponame: "static-eccommerce-nextjs",
    price: 3000,
    languages: ["Nextjs", "React", "Tailwindcss"],
  },
  {
    image: project107,
    name: "Dashboard Vite website",
    desc: "Responsive Dashboard Vite website",
    date: "11 March 2025",
    link: "https://dashboard-ui-reactjs-vite.vercel.app",
    reponame: "dashboard-ui-reactjs-vite",
    price: 500,
    languages: ["Vite", "React", "Css"],
  },
  {
    image: project108,
    name: "Real Estate Property Sale Website",
    desc: "Responsive Real Estate Property Sale Website",
    date: "11 March 2025",
    link: "https://three-dolts-real-estate-property-sale.vercel.app",
    reponame: "three-dolts-real-estate-property-sale",
    price: 5000,
    languages: ["Vite", "React", "Tailwindcss", "Nodejs"],
  },
  {
    image: project109,
    name: "Mern Payment Intergration - Paypal, Stripe and Mpesa (Payhero)",
    desc: "This is a full payments intergration app using paypal,stripe and (Mpesa using payhero as third party - for RELIABILITY).",
    date: "27 March 2025",
    link: "https://mern-payment-integration.vercel.app",
    reponame: "mern-payment-integration",
    price: 1000,
    languages: ["Vite", "React", "Nodejs", "Javascript"],
  },
];

export default projects;
