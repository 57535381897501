import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import toast from "react-hot-toast";
import axios from "axios";
import { server } from "../../server";

const GiftUs = () => {
  const [mpesaLoading, setMpesaLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [amount, setAmount] = useState("");
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(false);

  const handlePhoneChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };

  const handleAmountOptionClick = (value) => {
    if (value === "custom") {
      setSelectedAmount("custom");
      setAmount("");
    } else {
      setSelectedAmount(value);
      setAmount(value);
    }
  };

  const payheroHandler = async (phone, amount) => {
    setMpesaLoading(true);
    const toastId = toast.loading("Loading...");
    try {
      await axios
        .post(`${server}/payhero-stk`, {
          amount: Math.floor(amount),
          phone: phone,
        })
        .then(async (res) => {
          toast.dismiss(toastId);
          toast.success("stk pushed");
          toast.custom(
            (t) => (
              <div
                style={{
                  maxWidth: "30rem",
                  width: "100%",
                  backgroundColor: "white",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  borderRadius: "0.5rem",
                  pointerEvents: "auto",
                  display: "block",
                  flexDirection: "row",
                  opacity: t.visible ? 1 : 0,
                  transition: "opacity 0.3s ease",
                  border: "1px solid rgba(0, 0, 0, 0.05)",
                }}
              >
                <div
                  style={{ width: "100%", padding: "1rem", flex: "1 1 auto" }}
                >
                  <div style={{ display: "flex", alignItems: "start" }}>
                    <div style={{ marginLeft: "0.75rem", flex: 1 }}>
                      <p
                        style={{
                          fontSize: "13px",
                          fontWeight: "500",
                          color: "#1F2937",
                        }}
                      >
                        We Sent you STK push to your Phone
                      </p>
                      <p
                        style={{
                          marginTop: "0.25rem",
                          fontSize: "13px",
                          color: "#6B7280",
                        }}
                      >
                        Please enter your PIN to complete payments
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    borderTop: "1px solid rgba(229, 229, 229, 1)",
                  }}
                >
                  <button
                    onClick={() => toast.dismiss(t.id)}
                    style={{
                      width: "100%",
                      padding: "1rem",
                      fontSize: "13px",
                      fontWeight: "500",
                      color: "#6366F1",
                      cursor: "pointer",
                      transition: "color 0.2s ease",
                      borderTop: "1px solid rgba(229, 229, 229, 1)",
                      borderRight: "1px solid rgba(229, 229, 229, 1)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                    onMouseOver={(e) =>
                      (e.currentTarget.style.color = "#4F46E5")
                    }
                    onMouseOut={(e) =>
                      (e.currentTarget.style.color = "#6366F1")
                    }
                  >
                    Close
                  </button>
                </div>
              </div>
            ),
            { duration: 30000 }
          );

          const checkPaymentStatus = async (res) => {
            const toastId = toast.loading("Verifying Payment....");
            setTimeout(async () => {
              try {
                const resp = await axios.get(
                  `${server}/payhero-check-status/${res.data.data.reference}`,
                  null
                );
                setMpesaLoading(false);

                if (resp.data.data.status === "SUCCESS") {
                  toast.dismiss(toastId);
                  toast.success("Payment Successful. Thanks Alot.");
                  setIsPaymentSuccessful(true);
                } else {
                  toast.dismiss(toastId);
                  toast.error("Payments was not confirmed");
                  toast.custom(
                    (a) => (
                      <div
                        style={{
                          maxWidth: "30rem",
                          width: "100%",
                          backgroundColor: "white",
                          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                          borderRadius: "0.5rem",
                          pointerEvents: "auto",
                          display: "block",
                          flexDirection: "row",
                          border: "1px solid rgba(0, 0, 0, 0.1)",
                          opacity: a.visible ? 1 : 0,
                          transition: "opacity 0.3s ease",
                        }}
                      >
                        <div style={{ flex: 1, padding: "1rem" }}>
                          <div style={{ display: "flex", alignItems: "start" }}>
                            <div style={{ marginLeft: "0.75rem", flex: 1 }}>
                              <p
                                style={{
                                  fontSize: "13px",
                                  fontWeight: "500",
                                  color: "#1F2937",
                                }}
                              >
                                {" "}
                                We Could not Verify Your Payment.
                              </p>
                              <p
                                style={{
                                  marginTop: "0.25rem",
                                  fontSize: "13px",
                                  color: "#6B7280",
                                }}
                              >
                                {" "}
                                If Payments was deducted from your account,
                                click Re-Confirm.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "block",
                            borderTop: "1px solid rgba(229, 229, 229, 1)",
                          }}
                        >
                          {" "}
                          <button
                            onClick={() => {
                              toast.dismiss(a.id);
                              checkPaymentStatus(resp);
                            }}
                            style={{
                              width: "100%",
                              borderRight: "1px solid rgba(74, 222, 128, 1)",
                              borderRadius: "0",
                              padding: "1rem",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              fontSize: "13px",
                              fontWeight: "500",
                              color: "#6366F1",
                              cursor: "pointer",
                              transition: "color 0.2s ease",
                            }}
                            onMouseOver={(e) =>
                              (e.currentTarget.style.color = "#4F46E5")
                            }
                            onMouseOut={(e) =>
                              (e.currentTarget.style.color = "#6366F1")
                            }
                          >
                            Re-Confirm
                          </button>
                          <button
                            onClick={() => {
                              toast.dismiss(a.id);
                            }}
                            style={{
                              width: "100%",
                              borderRadius: "0",
                              padding: "1rem",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              fontSize: "13px",
                              fontWeight: "500",
                              color: "#6366F1",
                              cursor: "pointer",
                              transition: "color 0.2s ease",
                              borderTop: "1px solid rgba(229, 229, 229, 1)",
                            }}
                            onMouseOver={(e) =>
                              (e.currentTarget.style.color = "#4F46E5")
                            }
                            onMouseOut={(e) =>
                              (e.currentTarget.style.color = "#6366F1")
                            }
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    ),
                    { duration: 30000 }
                  );
                }
              } catch (error) {
                toast.dismiss(toastId);
                setMpesaLoading(false);
                toast.error("Error Occurred, Please try Again!");
                console.log(error);
              }
            }, 30000);
          };
          checkPaymentStatus(res);
        })
        .catch((error) => {
          setMpesaLoading(false);
          toast.dismiss(toastId);
          toast.error("Error Ocurred, Please try Again Later.");
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitPayment = () => {
    const phoneRegex = /^\d{10}$/;
    if (amount && phoneNumber && phoneRegex.test(phoneNumber)) {
      payheroHandler(phoneNumber, amount);
    } else if (!phoneRegex.test(phoneNumber)) {
      toast.error("Oops! Please enter a valid 10-digit phone number.", {
        duration: 10000,
      });
    } else {
      toast.error("Oops! Please select amount and a 10-digit phone number.", {
        duration: 10000,
      });
    }
  };

  return (
    <>
      <Header color="#51558a" />
      <div
        style={{
          marginTop: "70px",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#2629321c",
          padding: "50px 0",
        }}
      >
        <div
          style={{
            width: "360px",
            backgroundColor: "#ffffff",
            borderRadius: "12px",
            padding: "30px",
            textAlign: "center",
          }}
        >
          {isPaymentSuccessful ? (
            <div>
              <h2
                style={{
                  fontSize: "22px",
                  fontWeight: "600",
                  color: "#0f172a",
                  marginBottom: "10px",
                }}
              >
                Thank You! 🎉
              </h2>
              <p
                style={{
                  fontSize: "16px",
                  color: "#1e293b",
                  marginBottom: "20px",
                }}
              >
                Your payment was successful. We appreciate your support!
              </p>
              <button
                style={{
                  backgroundColor: "#0ea5e9",
                  color: "#ffffff",
                  padding: "12px 20px",
                  border: "none",
                  borderRadius: "6px",
                  cursor: "pointer",
                  fontSize: "16px",
                  fontWeight: "600",
                  width: "100%",
                  transition: "background-color 0.3s",
                }}
                onMouseOver={(e) =>
                  (e.target.style.backgroundColor = "#0284c7")
                }
                onMouseOut={(e) => (e.target.style.backgroundColor = "#0ea5e9")}
                onClick={() => setIsPaymentSuccessful(false)}
              >
                Send Another Gift
              </button>
            </div>
          ) : (
            <>
              <h2
                style={{
                  fontSize: "22px",
                  fontWeight: "600",
                  color: "#0f172a",
                  marginBottom: "10px",
                }}
              >
                Give Us a Gift🎁
              </h2>
              <div
                style={{
                  display: "flex",
                  gap: 2,
                  marginBottom: "20px",
                  justifyContent: "center",
                }}
              >
                {[100, 500, 1000].map((value) => (
                  <div
                    key={value}
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor:
                        selectedAmount === value ? "#0ea5e9" : "#f1f5f9",
                      padding: "12px 15px",
                      borderRadius: "5px",
                      cursor: "pointer",
                      color: selectedAmount === value ? "#ffffff" : "#1e293b",
                    }}
                    onClick={() => handleAmountOptionClick(value)}
                  >
                    <span style={{ fontSize: "14px" }}>
                      <strong>{value}</strong>
                    </span>
                  </div>
                ))}
                <div
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor:
                      selectedAmount === "custom" ? "#0ea5e9" : "#f1f5f9",
                    padding: "12px 15px",
                    borderRadius: "5px",
                    cursor: "pointer",
                    color: selectedAmount === "custom" ? "#ffffff" : "#1e293b",
                  }}
                  onClick={() => handleAmountOptionClick("custom")}
                >
                  <span style={{ fontSize: "14px" }}>
                    <strong>Custom</strong>
                  </span>
                </div>
              </div>

              {selectedAmount === "custom" && (
                <div style={{ marginBottom: "20px" }}>
                  <label
                    htmlFor="email"
                    style={{
                      display: "flex",
                      fontSize: "1.2rem",
                      marginBottom: "6px",
                    }}
                  >
                    Amount{" "}
                  </label>{" "}
                  <input
                    type="number"
                    placeholder="Enter Amount."
                    value={amount}
                    onChange={handleAmountChange}
                    style={{
                      width: "100%",
                      padding: "8px",
                      borderRadius: "4px",
                      border: "1px solid #4A4A4A",
                      fontSize: "1.4rem",
                      marginBottom: "12px",
                    }}
                    min={1}
                  />
                </div>
              )}

              <div style={{ marginBottom: "20px" }}>
                <label
                  htmlFor="email"
                  style={{
                    display: "flex",
                    fontSize: "1.2rem",
                    marginBottom: "6px",
                  }}
                >
                  Phone*{" "}
                  <span style={{ fontSize: "11px", margin: "2px 0 0 2px" }}>
                    (We will send the stk push here.)
                  </span>
                </label>{" "}
                <input
                  type="tel"
                  placeholder="Enter phone number"
                  value={phoneNumber}
                  onChange={handlePhoneChange}
                  style={{
                    width: "100%",
                    padding: "8px",
                    borderRadius: "4px",
                    border: "1px solid #4A4A4A",
                    fontSize: "1.4rem",
                    marginBottom: "12px",
                  }}
                />
              </div>
              <ul
                style={{
                  listStyle: "none",
                  padding: 0,
                  textAlign: "left",
                  margin: "20px 0",
                }}
              >
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "12px",
                    color: "#1e293b",
                    fontSize: "14px",
                    justifyContent: "center",
                  }}
                >
                  <p
                    style={{
                      width: "18px",
                      height: "18px",
                      marginRight: "10px",
                    }}
                  >
                    😊
                  </p>
                  Thanks for the support
                </li>
              </ul>

              <button
                style={{
                  backgroundColor: "#0ea5e9",
                  color: "#ffffff",
                  padding: "12px 20px",
                  border: "none",
                  borderRadius: "6px",
                  cursor: "pointer",
                  fontSize: "16px",
                  fontWeight: "600",
                  width: "100%",
                  transition: "background-color 0.3s",
                }}
                onMouseOver={(e) =>
                  (e.target.style.backgroundColor = "#0284c7")
                }
                onMouseOut={(e) => (e.target.style.backgroundColor = "#0ea5e9")}
                onClick={() => handleSubmitPayment()}
                disabled={mpesaLoading}
              >
                {mpesaLoading ? "Sending..." : "Send Gift"}
              </button>
            </>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default GiftUs;
