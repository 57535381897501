const projects = [
  {
    image:
      "https://res.cloudinary.com/dxxhtz2dw/image/upload/v1738307774/three%20dolts%20website/gqfsan8qwark6akwo6jz.png",
    name: "Ninetyone - ecommerce website",
    desc: "Best Selling Multi Vendor ecommerce Website. Empower Your Business with the Top-Performing Multi-Vendor E-commerce Platform",
    date: "21 Dec 2020",
    link: "https://www.ninetyone.co.ke/",
    likes: 14,
  },
  {
    image:
      "https://res.cloudinary.com/dxxhtz2dw/image/upload/v1738307775/three%20dolts%20website/gmj3qdbig5ainuidmeyp.png",
    name: "Ninetyone subsidiary Website",
    desc: "Single Vender E-commerce business, a subsidiary of ninetyone.co.ke.",
    date: "26 Dec 2020",
    link: "https://ninetyone.vercel.app/",
    likes: 22,
  },
  {
    image:
      "https://res.cloudinary.com/dxxhtz2dw/image/upload/v1738307836/three%20dolts%20website/ru3krtqi5onvswpkoype.png",
    name: "Three Dolts Education- LMS",
    desc: "Empower Your Future with Valuable Skills",
    date: "30 Sebtember 2024",
    link: "https://threed-edu.vercel.app",
  },
  {
    image:
      "https://res.cloudinary.com/dxxhtz2dw/image/upload/v1738307775/three%20dolts%20website/akkvij6rpxigkjnh9a94.png",
    name: "Nonex Computers Website - Thika ",
    desc: " With a commitment to seamless user experiences and cutting-edge technologies, I specialize in turning ideas into captivating realities.",
    date: "15 Feb 2021",
    link: "https://nonexcomputers.com/",
    likes: 19,
  },
  {
    image:
      "https://res.cloudinary.com/dxxhtz2dw/image/upload/v1738307729/three%20dolts%20website/k35p9tqp5t1h9iyxm5ah.png",
    name: "Lenders Bay Credit Website",
    desc: "This is a loan management stystem website with fully fuctional admin dashboard and a users page. It manages loans from the day of release to maturity.",
    date: "25 Feb 2021",
    link: "https://lendersbaycredit.com/",
    likes: 6,
  },
  {
    image:
      "https://res.cloudinary.com/dxxhtz2dw/image/upload/v1738307810/three%20dolts%20website/ksqyc4mekhhgbuke2wux.png",
    name: "BigShot Computers Website",
    desc: "BigShot Computers offers high-performance PCs, accessories, and custom builds, providing seamless shopping and expert support for tech enthusiasts and professionals.",
    date: "18 Jan 2025",
    link: "https://bigshotcomputers.co.ke",
    likes: 11,
  },
  {
    image:
      "https://res.cloudinary.com/dxxhtz2dw/image/upload/v1738308349/three%20dolts%20website/m0qfo3dnsrc4t36dz16i.png",
    name: "Steward Cars",
    desc: "Steward Cars provides reliable and affordable car hire services, offering a wide range of vehicles to suit all travel needs with exceptional customer service.",
    date: "18 Jan 2025",
    link: "https://stewardcars.com",
    likes: 13,
  },
  {
    image:
      "https://res.cloudinary.com/dxxhtz2dw/image/upload/v1738308419/three%20dolts%20website/iqybvu1l9xyejhecraw2.png",
    name: "Land Agent Website",
    desc: "Your satisfaction is our top priority. We actively seek your feedback to continuously improve and ensure a fair and transparent experience.",
    date: "11 July 2022",
    link: "https://pafriminvestments.com/",
    likes: 15,
  },
  {
    image:
      "https://res.cloudinary.com/dxxhtz2dw/image/upload/v1738307668/three%20dolts%20website/txzaryrysrebea69dzji.png",
    name: "3 Dolts Websites",
    desc: "We Design Interfaces That Users Love",
    date: "10 July 2020",
    link: "https://threedolts.co.ke/",
    likes: 15,
  },
  {
    image:
      "https://res.cloudinary.com/dxxhtz2dw/image/upload/v1738307693/three%20dolts%20website/sd2yg6idyc3gixjnuknp.png",
    name: "Bani Logistics Websites",
    desc: "We're Global Logistics Partners",
    date: "In Progress",
    link: "https://www.banilogistics.co.ke/",
    likes: 15,
  },
  {
    image:
      "https://res.cloudinary.com/dxxhtz2dw/image/upload/v1738307889/three%20dolts%20website/lpsentzfeg13vyfwfmt5.png",
    name: "Insurance Co Website",
    desc: "Life insurance makes your life happier",
    date: "20 July 2024",
    link: "https://insurance-hazel.vercel.app/",
  },
  {
    image:
      "https://res.cloudinary.com/dxxhtz2dw/image/upload/v1738308529/three%20dolts%20website/q4vvvex8c4bteegepqtw.png",
    name: "Lugi Spices Website",
    desc: "We don't just spice, we create your emotions!",
    date: "05 Aug 2024",
    link: "https://www.lunghispices.co.ke",
  },
  {
    image:
      "https://res.cloudinary.com/dxxhtz2dw/image/upload/v1738307749/three%20dolts%20website/c4mhynpirrcstcuwpded.png",
    name: "Salvian Energies Websites",
    desc: "Delivering top-quality petroleum with unmatched reliability to keep your operations running smoothly.",
    date: "17 August 2024",
    link: "https://www.salvianenergies.com",
  },
  {
    image:
      "https://res.cloudinary.com/dxxhtz2dw/image/upload/v1738307952/three%20dolts%20website/errdm6pfylvvvsea2yfc.png",
    name: "Fitlife Gym Websites",
    desc: "Work Hard To Get Better Life Welcome To Our Fitness Gym",
    date: "27 August 2024",
    link: "https://fitlife-gym.vercel.app",
  },
  {
    image:
      "https://res.cloudinary.com/dxxhtz2dw/image/upload/v1738308004/three%20dolts%20website/ff8vmauh0eymfac6rxf3.png",
    name: "Hybrid Motors Solution",
    desc: "Auto Maintenance & Repair Service. Our mission to provide top-notch automotive repair and maintenance services with integrity and professionalism.",
    date: "30 September 2024",
    link: "https://hybridmotorssolutions.co.ke",
  },
  {
    image:
      "https://res.cloudinary.com/dxxhtz2dw/image/upload/v1738307970/three%20dolts%20website/nzm2rsb8qd8ilnm7f5ur.png",
    name: "Movies Website",
    desc: "Welcome. Millions of movies, TV shows and people to discover. Explore now.",
    date: "27 Sebtember 2024",
    link: "https://movies-website-sooty.vercel.app",
  },
  {
    image:
      "https://res.cloudinary.com/dxxhtz2dw/image/upload/v1738308626/three%20dolts%20website/gvuzxpyx2mw7zmzrgobt.png",
    name: "Adongo Car Rentals and Hire",
    desc: "Reliable Car Services for Your Comfort and Convenience",
    date: "7 January 2025",
    link: "https://www.adongocarrentalandhire.co.ke",
  },
  {
    image:
      "https://res.cloudinary.com/dxxhtz2dw/image/upload/v1738308658/three%20dolts%20website/jqinkutvj5ycxfnliiqh.png",
    name: "Blogs Website - Three Dolts",
    desc: "Responsive clean UI blogs websites",
    date: "30 January 2025",
    link: "https://www.blogs.threedolts.co.ke",
  },
];
export default projects;
