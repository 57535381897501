import React from "react";
import { Link } from "react-router-dom";
import logowhite from "../../images/logo-white-nobg.png";
import { TbBrandTiktok } from "react-icons/tb";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { IoLogoYoutube } from "react-icons/io";
import { FaXTwitter } from "react-icons/fa6";
import { FaHeadphonesAlt } from "react-icons/fa";
import { IoMailOutline } from "react-icons/io5";
import { HiLocationMarker } from "react-icons/hi";

const Footer = () => {
  return (
    <>
      {" "}
      <footer class="footer">
        <div class="footer-top section">
          <div class="container">
            <div class="footer-brand">
              <Link to="/" class="logo">
                <img
                  src={logowhite}
                  alt="3doltslogo"
                  style={{
                    with: "50px",
                    height: "50px",
                  }}
                />
              </Link>

              <p class="text">
                Our mission is to help individuals and organizations navigate
                complex challenges and achieve their goals through strategic
                guidance and effective mediation.
              </p>

              <ul class="social-list">
                <li>
                  <a
                    href="https://www.tiktok.com/@threedolts"
                    className="social-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <TbBrandTiktok style={{ color: "#000" }} />
                  </a>
                </li>

                <li>
                  <a
                    href="https://www.facebook.com/threedolts/"
                    class="social-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaFacebook style={{ color: "#1877F2" }} />
                  </a>
                </li>

                <li>
                  <a
                    href="https://www.instagram.com/threedolts"
                    class="social-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaInstagram />
                  </a>
                </li>
                <li>
                  <a
                    href="https://x.com/threedolts"
                    class="social-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaXTwitter color="#000" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/@three-dolts/videos"
                    class="social-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <IoLogoYoutube />
                  </a>
                </li>
              </ul>
            </div>

            <ul class="footer-list">
              <li>
                <p class="footer-list-title">Our links</p>
              </li>

              <li>
                <Link to="/" class="footer-link">
                  Home
                </Link>
              </li>

              <li>
                <Link to="/about" class="footer-link">
                  About Us
                </Link>
              </li>

              <li>
                <Link to="/services" class="footer-link">
                  Services
                </Link>
              </li>

              <li>
                <Link to="/pricing" class="footer-link">
                  Pricing
                </Link>
              </li>

              <li>
                <Link to="/blog" class="footer-link">
                  Blog
                </Link>
              </li>
            </ul>

            <ul class="footer-list">
              <li>
                <p class="footer-list-title">Our Services</p>
              </li>

              <li>
                <Link to="/" class="footer-link">
                  Website Design and Developemt.
                </Link>
              </li>

              <li>
                <Link to="/" class="footer-link">
                  Business Consultancy
                </Link>
              </li>

              <li>
                <Link to="/" class="footer-link">
                  Training & Development
                </Link>
              </li>

              <li>
                <Link to="/" class="footer-link">
                  Digital Marketing
                </Link>
              </li>

              <li>
                <Link to="/" class="footer-link">
                  App Design
                </Link>
              </li>
            </ul>

            <ul class="footer-list">
              <li>
                <p class="footer-list-title">Resources</p>
              </li>

              <li>
                <a
                  href="https://threed-edu.vercel.app"
                  target="__blank"
                  class="footer-link"
                >
                  3D Education
                </a>
              </li>

              <li>
                <Link to="/gift-us" class="footer-link">
                  Gift Us
                </Link>
              </li>

              <li>
                <a
                  href="https://ninetyone.co.ke/"
                  target="__blank"
                  class="footer-link"
                >
                  3D Ecommerce
                </a>
              </li>

              <li>
                <Link to="/" class="footer-link">
                  Terms & Conditions
                </Link>
              </li>

              <li>
                <Link to="/" class="footer-link">
                  Support
                </Link>
              </li>
            </ul>

            <ul class="footer-list">
              <li>
                <p class="footer-list-title">Contact Us</p>
              </li>

              <li class="footer-item">
                <div class="footer-item-icon">
                  <FaHeadphonesAlt />
                </div>

                <div>
                  <a href="tel:+2484214313" class="footer-item-link">
                    +254-791-004-783
                  </a>
                  <a href="tel:+2486871365" class="footer-item-link">
                    +254-712-012-113
                  </a>
                </div>
              </li>

              <li class="footer-item">
                <div class="footer-item-icon">
                  <IoMailOutline />
                </div>

                <div>
                  <a href="info@threedolts.co.ke" class="footer-item-link">
                    info@threedolts.co.ke
                  </a>
                  <a
                    href="threedoltswebsites@gmail.com"
                    class="footer-item-link"
                  >
                    threedoltswebsites @gmail.com
                  </a>
                </div>
              </li>

              <li class="footer-item">
                <div class="footer-item-icon">
                  <HiLocationMarker />
                </div>

                <address class="footer-item-link">
                  Deliverance, Ruiru, Nairobi Kenya
                </address>
              </li>
            </ul>
          </div>
        </div>

        <div class="footer-bottom">
          <p class="copyright">
            &copy; 2024{" "}
            <Link to="/" class="copyright-link">
              three-dolts
            </Link>
            . All Right Reserved
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
