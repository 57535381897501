import React from "react";
// import feature from "../../images/feautres-banner.png";
import feature from "../../images/features.svg";
import { LuLightbulb } from "react-icons/lu";
import { IoColorPaletteOutline } from "react-icons/io5";
import { FaCode } from "react-icons/fa6";
import { GrDeploy } from "react-icons/gr";

const Features = () => {
  return (
    <>
      <section className="section features" id="features">
        <div className="container">
          <h2 className="h2 section-title underline">Our Features</h2>

          <ul className="features-list">
            <li>
              <div className="features-card">
                <div className="icon">
                  <LuLightbulb size={22} />
                </div>

                <div className="content">
                  <h3 className="h3 title">Idea & Analysis</h3>

                  <p className="text">
                    We brainstorm innovative ideas and analyze their feasibility
                    to align with your business goals.
                  </p>
                </div>
              </div>
            </li>

            <li>
              <div className="features-card">
                <div className="icon">
                  <IoColorPaletteOutline size={22} />
                </div>

                <div className="content">
                  <h3 className="h3 title">Designing</h3>

                  <p className="text">
                    We create visually appealing, user-friendly interfaces with
                    intuitive navigation.
                  </p>
                </div>
              </div>
            </li>
          </ul>

          <figure className="features-banner">
            <img
              src={feature}
              width="369"
              height="318"
              loading="lazy"
              alt="Features Banner"
              className="w-100 banner-animation"
            />
          </figure>

          <ul className="features-list">
            <li>
              <div className="features-card">
                <div className="icon">
                  <FaCode size={22} />
                </div>

                <div className="content">
                  <h3 className="h3 title">Development</h3>

                  <p className="text">
                    We turn designs into functional, scalable software using
                    modern technologies.
                  </p>
                </div>
              </div>
            </li>

            <li>
              <div className="features-card">
                <div className="icon">
                  <GrDeploy size={22} />
                </div>

                <div className="content">
                  <h3 className="h3 title">Testing & Launching</h3>

                  <p className="text">
                    We rigorously test and evaluate your product for a smooth
                    launch and offer post-launch support.
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default Features;
