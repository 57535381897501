import React from "react";

const TrustedBrands = () => {
  const brands = [
    "https://res.cloudinary.com/bramuels/image/upload/v1695878268/logo/LOGO-01_moo9oc.png",
    "https://res.cloudinary.com/dxxhtz2dw/image/upload/v1702714579/samples/nonex%20computers/slidecat/slide-5_zd06mu.png",
    "https://res.cloudinary.com/dmgy3huhm/image/upload/v1734361260/logo/mhf6so4uh15aoqgpnaiv.png",
    "https://stewardcars.com/img/steward-logo.png",
    "https://www.banilogistics.co.ke/static/media/logo.40706563a145d3ce7fdd.png",
    "https://www.lunghispices.co.ke/assets/images/logo.png",
    "https://www.salvianenergies.com/img/logo.jpg",
    "https://hybridmotorssolutions.co.ke/assets/images/logo2.jpg",
  ];

  const duplicatedBrands = [...brands, ...brands];

  return (
    <div className="">
      <div className="trusted-brands">
        <h2>We are trusted by these leading brands</h2>
        <div className="brands-container">
          <div className="brands-scroll">
            {duplicatedBrands.map((brand, index) => (
              <img
                key={index}
                src={brand}
                alt={`Brand ${index + 1}`}
                className="brand-logo"
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrustedBrands;
