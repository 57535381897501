import React, { useState, useMemo, useRef } from "react";
import Header from "./common/Header.jsx";
import Footer from "./common/Footer.jsx";
import projects from "./projectsdata.js";
import toast from "react-hot-toast";
import Pagination from "./common/Pagination.jsx";
import Modal from "./common/Modal.jsx";
import axios from "axios";
import { server } from "../server.js";
import { FaCopy } from "react-icons/fa";
import { HiOutlineHeart } from "react-icons/hi2";
import { HiMiniHeart } from "react-icons/hi2";
import { AiOutlineEye } from "react-icons/ai";
import { LuCalendarCheck2 } from "react-icons/lu";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { IoSearch } from "react-icons/io5";

// Function to shuffle an array
const shuffleArray = (array) => {
  let shuffledArray = array.slice();
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
};

const Projects = () => {
  // Shuffle projects on component mount
  const shuffledProjects = useMemo(() => shuffleArray(projects), []);

  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedProject, setSelectedProject] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [mpesaLoading, setMpesaLoading] = useState(false);

  const itemsPerPage = 10;
  const filteredProjects = shuffledProjects.filter((project) =>
    project.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const totalPages = Math.ceil(filteredProjects.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentProjects = filteredProjects.slice(startIndex, endIndex);

  const sectionRef = useRef(null);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }

    // Scroll to the section after the page is updated
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const getRandomInt = (min, max) =>
    Math.floor(Math.random() * (max - min + 1)) + min;

  // Initialize likes and liked state for each project
  const initialLikes = currentProjects.reduce((acc, _, index) => {
    acc[index] = getRandomInt(10, 100); // Initialize likes
    return acc;
  }, {});

  const initialLikedState = currentProjects.reduce((acc, _, index) => {
    acc[index] = false; // Initialize as not liked
    return acc;
  }, {});

  const [likes, setLikes] = useState(initialLikes);
  const [liked, setLiked] = useState(initialLikedState);

  const handleLikeToggle = (projectIndex) => {
    setLikes((prevLikes) => {
      const newLikes = { ...prevLikes };
      const isLiked = liked[projectIndex];
      if (isLiked) {
        newLikes[projectIndex] = Math.max(0, newLikes[projectIndex] - 1);
      } else {
        newLikes[projectIndex] = (newLikes[projectIndex] || 0) + 1;
      }
      return newLikes;
    });

    setLiked((prevLiked) => ({
      ...prevLiked,
      [projectIndex]: !prevLiked[projectIndex],
    }));
    liked[projectIndex]
      ? toast.error("Oops! Like removed", { duration: 10000 })
      : toast.success("Thanks for a Like", { duration: 10000 });
  };

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("Copied to clipboard!");
      })
      .catch((err) => {
        toast.error("Failed to copy!");
      });
  };

  const ToastWithCopy = ({ message }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span style={{ display: "flex" }}>
        Our email:{" "}
        <span style={{ color: "green", marginLeft: "4px" }}>{message}</span>
      </span>
      <FaCopy
        style={{ marginLeft: "8px", cursor: "pointer", color: "green" }}
        onClick={() => copyToClipboard(message)}
      />
    </div>
  );

  const goToProject = (link) => {
    window.open(link, "_blank", "noreferrer");
  };

  const sendGitHubInvite = async (username, reponame) => {
    try {
      const response = await axios.post(`${server}/sendinvitation`, {
        username: username,
        reponame: reponame,
      });

      if (response.status === 200) {
        toast.success("Invitation was sent to your Email", {
          duration: 30000,
        });
      }
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(`Error: ${error.response.data.message}`);
        toast.custom(
          (a) => (
            <div
              style={{
                maxWidth: "30rem",
                width: "100%",
                backgroundColor: "white",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                borderRadius: "0.5rem",
                pointerEvents: "auto",
                display: "block",
                flexDirection: "row",
                border: "1px solid rgba(0, 0, 0, 0.1)",
                opacity: a.visible ? 1 : 0,
                transition: "opacity 0.3s ease",
              }}
            >
              <div style={{ flex: 1, padding: "1rem" }}>
                <div style={{ display: "flex", alignItems: "start" }}>
                  <div style={{ marginLeft: "0.75rem", flex: 1 }}>
                    <p
                      style={{
                        fontSize: "13px",
                        fontWeight: "500",
                        color: "#1F2937",
                      }}
                    >
                      {" "}
                      We did not find a github username you provided.
                    </p>
                    <p
                      style={{
                        marginTop: "0.25rem",
                        fontSize: "13px",
                        color: "#6B7280",
                      }}
                    >
                      {" "}
                      Please email{" "}
                      <ToastWithCopy message={"info@threedolts.co.ke"} /> and we
                      will send you the link direct to your email.
                    </p>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "block",
                  borderTop: "1px solid rgba(229, 229, 229, 1)",
                }}
              >
                <button
                  onClick={() => {
                    toast.dismiss(a.id);
                  }}
                  style={{
                    width: "100%",
                    borderRadius: "0",
                    padding: "1rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "13px",
                    fontWeight: "500",
                    color: "#6366F1",
                    cursor: "pointer",
                    transition: "color 0.2s ease",
                    borderTop: "1px solid rgba(229, 229, 229, 1)",
                  }}
                  onMouseOver={(e) => (e.currentTarget.style.color = "#4F46E5")}
                  onMouseOut={(e) => (e.currentTarget.style.color = "#6366F1")}
                >
                  Close
                </button>
              </div>
            </div>
          ),
          { duration: 30000 }
        );
      } else {
        toast.error("An error occurred while sending the GitHub invite.");
        toast.custom(
          (a) => (
            <div
              style={{
                maxWidth: "30rem",
                width: "100%",
                backgroundColor: "white",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                borderRadius: "0.5rem",
                pointerEvents: "auto",
                display: "block",
                flexDirection: "row",
                border: "1px solid rgba(0, 0, 0, 0.1)",
                opacity: a.visible ? 1 : 0,
                transition: "opacity 0.3s ease",
              }}
            >
              <div style={{ flex: 1, padding: "1rem" }}>
                <div style={{ display: "flex", alignItems: "start" }}>
                  <div style={{ marginLeft: "0.75rem", flex: 1 }}>
                    <p
                      style={{
                        fontSize: "13px",
                        fontWeight: "500",
                        color: "#1F2937",
                      }}
                    >
                      {" "}
                      We did not find a github username you provided.
                    </p>
                    <p
                      style={{
                        marginTop: "0.25rem",
                        fontSize: "13px",
                        color: "#6B7280",
                      }}
                    >
                      {" "}
                      Please email{" "}
                      <ToastWithCopy message={"info@threedolts.co.ke"} /> and we
                      will send you the link direct to your email.
                    </p>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "block",
                  borderTop: "1px solid rgba(229, 229, 229, 1)",
                }}
              >
                <button
                  onClick={() => {
                    toast.dismiss(a.id);
                  }}
                  style={{
                    width: "100%",
                    borderRadius: "0",
                    padding: "1rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "13px",
                    fontWeight: "500",
                    color: "#6366F1",
                    cursor: "pointer",
                    transition: "color 0.2s ease",
                    borderTop: "1px solid rgba(229, 229, 229, 1)",
                  }}
                  onMouseOver={(e) => (e.currentTarget.style.color = "#4F46E5")}
                  onMouseOut={(e) => (e.currentTarget.style.color = "#6366F1")}
                >
                  Close
                </button>
              </div>
            </div>
          ),
          { duration: 30000 }
        );
      }
    }
  };

  const payheroHandler = async (phone, price, username, reponame) => {
    setMpesaLoading(true);
    const toastId = toast.loading("Loading...");
    try {
      await axios
        .post(`${server}/payhero-stk`, {
          amount: Math.floor(price),
          phone: phone,
        })
        .then(async (res) => {
          toast.dismiss(toastId);
          toast.success("stk pushed");
          toast.custom(
            (t) => (
              <div
                style={{
                  maxWidth: "30rem",
                  width: "100%",
                  backgroundColor: "white",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  borderRadius: "0.5rem",
                  pointerEvents: "auto",
                  display: "block",
                  flexDirection: "row",
                  opacity: t.visible ? 1 : 0,
                  transition: "opacity 0.3s ease",
                  border: "1px solid rgba(0, 0, 0, 0.05)",
                }}
              >
                <div
                  style={{ width: "100%", padding: "1rem", flex: "1 1 auto" }}
                >
                  <div style={{ display: "flex", alignItems: "start" }}>
                    <div style={{ marginLeft: "0.75rem", flex: 1 }}>
                      <p
                        style={{
                          fontSize: "13px",
                          fontWeight: "500",
                          color: "#1F2937",
                        }}
                      >
                        We Sent you STK push to your Phone
                      </p>
                      <p
                        style={{
                          marginTop: "0.25rem",
                          fontSize: "13px",
                          color: "#6B7280",
                        }}
                      >
                        Please enter your PIN to complete payments
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    borderTop: "1px solid rgba(229, 229, 229, 1)",
                  }}
                >
                  <button
                    onClick={() => toast.dismiss(t.id)}
                    style={{
                      width: "100%",
                      padding: "1rem",
                      fontSize: "13px",
                      fontWeight: "500",
                      color: "#6366F1",
                      cursor: "pointer",
                      transition: "color 0.2s ease",
                      borderTop: "1px solid rgba(229, 229, 229, 1)",
                      borderRight: "1px solid rgba(229, 229, 229, 1)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                    onMouseOver={(e) =>
                      (e.currentTarget.style.color = "#4F46E5")
                    }
                    onMouseOut={(e) =>
                      (e.currentTarget.style.color = "#6366F1")
                    }
                  >
                    Close
                  </button>
                </div>
              </div>
            ),
            { duration: 30000 }
          );

          const checkPaymentStatus = async (res) => {
            const toastId = toast.loading("Verifying Payment....");
            setTimeout(async () => {
              try {
                const resp = await axios.get(
                  `${server}/payhero-check-status/${res.data.data.reference}`,
                  null
                );
                setMpesaLoading(false);

                if (resp.data.data.status === "SUCCESS") {
                  toast.dismiss(toastId);
                  setModalVisible(false);
                  await sendGitHubInvite(username, reponame);
                  toast.success("Payment Successful. Thanks Alot.");
                } else {
                  toast.dismiss(toastId);
                  toast.error("Payments was not confirmed");
                  toast.custom(
                    (a) => (
                      <div
                        style={{
                          maxWidth: "30rem",
                          width: "100%",
                          backgroundColor: "white",
                          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                          borderRadius: "0.5rem",
                          pointerEvents: "auto",
                          display: "block",
                          flexDirection: "row",
                          border: "1px solid rgba(0, 0, 0, 0.1)",
                          opacity: a.visible ? 1 : 0,
                          transition: "opacity 0.3s ease",
                        }}
                      >
                        <div style={{ flex: 1, padding: "1rem" }}>
                          <div style={{ display: "flex", alignItems: "start" }}>
                            <div style={{ marginLeft: "0.75rem", flex: 1 }}>
                              <p
                                style={{
                                  fontSize: "13px",
                                  fontWeight: "500",
                                  color: "#1F2937",
                                }}
                              >
                                {" "}
                                We Could not Verify Your Payment.
                              </p>
                              <p
                                style={{
                                  marginTop: "0.25rem",
                                  fontSize: "13px",
                                  color: "#6B7280",
                                }}
                              >
                                {" "}
                                If Payments was deducted from your account,
                                click Re-Confirm.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "block",
                            borderTop: "1px solid rgba(229, 229, 229, 1)",
                          }}
                        >
                          {" "}
                          <button
                            onClick={() => {
                              toast.dismiss(a.id);
                              checkPaymentStatus(resp);
                            }}
                            style={{
                              width: "100%",
                              borderRight: "1px solid rgba(74, 222, 128, 1)",
                              borderRadius: "0",
                              padding: "1rem",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              fontSize: "13px",
                              fontWeight: "500",
                              color: "#6366F1",
                              cursor: "pointer",
                              transition: "color 0.2s ease",
                            }}
                            onMouseOver={(e) =>
                              (e.currentTarget.style.color = "#4F46E5")
                            }
                            onMouseOut={(e) =>
                              (e.currentTarget.style.color = "#6366F1")
                            }
                          >
                            Re-Confirm
                          </button>
                          <button
                            onClick={() => {
                              toast.dismiss(a.id);
                            }}
                            style={{
                              width: "100%",
                              borderRadius: "0",
                              padding: "1rem",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              fontSize: "13px",
                              fontWeight: "500",
                              color: "#6366F1",
                              cursor: "pointer",
                              transition: "color 0.2s ease",
                              borderTop: "1px solid rgba(229, 229, 229, 1)",
                            }}
                            onMouseOver={(e) =>
                              (e.currentTarget.style.color = "#4F46E5")
                            }
                            onMouseOut={(e) =>
                              (e.currentTarget.style.color = "#6366F1")
                            }
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    ),
                    { duration: 30000 }
                  );
                }
              } catch (error) {
                toast.dismiss(toastId);
                setMpesaLoading(false);
                toast.error("Error Occurred, Please try Again!");
                console.log(error);
              }
            }, 30000);
          };
          checkPaymentStatus(res);
        })
        .catch((error) => {
          setMpesaLoading(false);
          toast.dismiss(toastId);
          toast.error("Error Ocurred, Please try Again Later.");
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleBuyCodeClick = (project) => {
    setSelectedProject(project);
    setModalVisible(true);
  }; //

  const modalData = {
    text1: `Pay sh. ${selectedProject?.price.toLocaleString()}.`,
    text2: "Please provide your Details.",
    mpesaLoading: mpesaLoading,
    btn1Text: "Submit",
    btn2Text: "Cancel",
    btn1Handler: (phoneNumber, username) => {
      const phoneRegex = /^\d{10}$/;

      if (username && phoneNumber && phoneRegex.test(phoneNumber)) {
        payheroHandler(
          phoneNumber,
          selectedProject?.price,
          username,
          selectedProject?.reponame
        );
      } else if (!phoneRegex.test(phoneNumber)) {
        toast.error("Oops! Please enter a valid 10-digit phone number.", {
          duration: 10000,
        });
      } else {
        toast.error(
          "Oops! Please enter an Github username and a 10-digit phone number.",
          {
            duration: 10000,
          }
        );
      }
    },

    btn2Handler: () => {
      toast.success("No worries! We're here whenever you're ready 😊💖");
      setModalVisible(false);
      setMpesaLoading(false);
    },
  };

  return (
    <>
      <Header color="#51558a" />
      {modalVisible && <Modal modalData={modalData} />}

      <section className="section blog" id="blog" style={{ marginTop: "70px" }}>
        <div className="container">
          <h2 className="h2 section-title underline">Works & Projects</h2>
          {/* Search input field */}
          <div
            className="search-container"
            style={{
              marginBottom: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            ref={sectionRef}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                borderRadius: "4px",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                maxWidth: "500px",
                width: "100%",
              }}
            >
              <IoSearch
                style={{
                  marginLeft: "10px",
                  color: "#888",
                  fontSize: "20px",
                  cursor: "pointer",
                }}
              />
              <input
                type="text"
                placeholder="Search by project name..."
                value={searchTerm}
                onChange={(e) => {
                  setCurrentPage(1);
                  setSearchTerm(e.target.value);
                }}
                style={{
                  padding: "10px",
                  border: "none",
                  borderRadius: "4px",
                  width: "100%",
                  fontSize: "16px",
                  outline: "none",
                }}
              />
            </div>
          </div>
          {filteredProjects.length === 0 ? (
            <p style={{ textAlign: "center", fontSize: "18px", color: "#888" }}>
              No Project found
            </p>
          ) : (
            <>
              <div className="page-indicator">
                <p>Thanks for your trust😊</p>
                <p>
                  Page {currentPage} of {totalPages}
                </p>
              </div>
              <ul className="blog-list">
                {currentProjects.map((project, index) => (
                  <li key={index}>
                    <div className="blog-card">
                      <figure className="banner">
                        <a href={project.link} target="_blank" rel="noreferrer">
                          <img
                            src={project.image}
                            width="750"
                            height="350"
                            loading="lazy"
                            alt={project.name}
                            className="img-cover"
                          />
                        </a>
                      </figure>

                      <div className="content">
                        <h3 className="h3 title">
                          <a
                            href={project.link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {project.name}
                          </a>
                          <a
                            className="publish-date"
                            style={{ fontSize: "12px" }}
                            href={project.link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <LuCalendarCheck2 size={20} />
                            <time datetime={project.date}>
                              updated: {project.date}
                            </time>
                          </a>
                        </h3>

                        <p className="text" style={{ marginBottom: "0" }}>
                          {project.desc}
                        </p>
                        <div
                          style={{
                            fontSize: "11px",
                            marginBottom: "5px",
                          }}
                        >
                          <h3 style={{ color: "#333", marginBottom: "5px" }}>
                            Languages Used
                          </h3>
                          <ul
                            style={{
                              listStyleType: "none",
                              padding: 0,
                              color: "#555",
                              display: "flex",
                              flexWrap: "wrap",
                            }}
                          >
                            {project.languages.map((language, index) => (
                              <li
                                key={index}
                                style={{
                                  margin: "5px 0",
                                  display: "flex",
                                  alignItems: "center",
                                  flexBasis: "30%",
                                }}
                              >
                                <IoCheckmarkCircleOutline
                                  style={{
                                    color: "#4CAF50",
                                    marginRight: "10px",
                                    fontSize: "16px",
                                    marginLeft: "3px",
                                  }}
                                />
                                {language}
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div
                          style={{
                            fontSize: "11px",
                            marginBottom: "10px",
                          }}
                        >
                          <h3 style={{ color: "#333" }}>
                            Price: sh. {project.price.toLocaleString()}
                          </h3>
                        </div>
                        <div className="meta">
                          <div
                            className="publish-date"
                            style={{
                              color: "#D7006C",
                              backgroundColor: "transparent",
                              padding: "9px 20px",
                              borderRadius: "3px",
                              cursor: "pointer",
                              transition:
                                "transform 0.3s, box-shadow 0.3s, border-color 0.3s",
                              border: "1px solid #D7006C",
                              textAlign: "center",
                              display: "inline-block",
                              textDecoration: "none",
                            }}
                            onClick={() => handleBuyCodeClick(project)}
                            onMouseEnter={(e) => {
                              e.target.style.transform = "scale(1.05)";
                              e.target.style.borderColor = "#FF3385";
                            }}
                            onMouseLeave={(e) => {
                              e.target.style.transform = "scale(1)";
                              e.target.style.borderColor = "#D7006C";
                            }}
                          >
                            Buy Code
                          </div>

                          <button
                            className="comment"
                            aria-label="Like"
                            onClick={() => handleLikeToggle(index)}
                          >
                            {liked[index] ? (
                              <HiMiniHeart size={24} color="green" />
                            ) : (
                              <HiOutlineHeart size={24} />
                            )}

                            <data value={likes[index] || 0}>
                              {likes[index] || 0}
                            </data>
                          </button>

                          <button
                            className="share"
                            aria-label="Share"
                            onClick={() => goToProject(project.link)}
                          >
                            <AiOutlineEye
                              size={22}
                              style={{ marginTop: "5px", color: "#EF1525" }}
                            />{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>

              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </>
          )}
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Projects;
