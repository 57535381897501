import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../../images/logo-nobg.png";
import logowhite from "../../images/logo-white-nobg.png";
import { IoChevronForward, IoClose } from "react-icons/io5";
import { MdMenuOpen } from "react-icons/md";
import { IoMdArrowRoundUp } from "react-icons/io";
import { AiOutlineWhatsApp } from "react-icons/ai";

const Header = ({ color }) => {
  const [isNavbarActive, setIsNavbarActive] = useState(false);
  const [isHeaderActive, setIsHeaderActive] = useState(false);
  const [isGoTopVisible, setIsGoTopVisible] = useState(false);

  // Toggle navbar and overlay
  const toggleNavbar = () => {
    setIsNavbarActive((prevState) => !prevState);
  };

  // Handle scroll event for header and go-top button
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 400) {
        setIsHeaderActive(true);
        setIsGoTopVisible(true);
      } else {
        setIsHeaderActive(false);
        setIsGoTopVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Handle keydown event
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "a" || event.key === "s") {
        // Handle key 'a' and 's' actions if needed
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => document.removeEventListener("keydown", handleKeyDown);
  }, []);

  return (
    <>
      <header
        className={`header ${isHeaderActive ? "active" : ""}`}
        data-header
      >
        <div className="container" style={{ position: "relative" }}>
          <div
            className={`overlay ${isNavbarActive ? "active" : ""}`}
            data-overlay
          ></div>

          <Link
            to="/"
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          >
            <h1 className="logo" style={{ color: `${color}` }}>
              {isGoTopVisible || color ? (
                <img
                  src={logo}
                  alt="3doltslogo"
                  style={{
                    with: "50px",
                    height: "50px",
                    position: "absolute",
                    top: "0",
                  }}
                />
              ) : (
                <img
                  src={logowhite}
                  alt="3doltslogo"
                  style={{
                    with: "50px",
                    height: "50px",
                    position: "absolute",
                    top: "0",
                  }}
                />
              )}
            </h1>
          </Link>

          <nav
            className={`navbar ${isNavbarActive ? "active" : ""}`}
            data-navbar
          >
            <div className="navbar-top">
              <Link to="/" className="logo" style={{ color: `${color}` }}>
                <img
                  src={logo}
                  alt="3doltslogo"
                  style={{
                    with: "50px",
                    height: "50px",
                  }}
                />
              </Link>

              <button
                className="nav-close-btn"
                aria-label="Close Menu"
                onClick={toggleNavbar}
                data-nav-close-btn
              >
                <IoClose size={24} />
              </button>
            </div>

            <ul className="navbar-list">
              <li className="navbar-item">
                <Link
                  to="/"
                  className="navbar-link"
                  data-navbar-link
                  style={{ color: `${color}` }}
                >
                  Home
                </Link>
              </li>
              <li className="navbar-item">
                <Link
                  to="/projects"
                  className="navbar-link"
                  data-navbar-link
                  style={{ color: `${color}` }}
                >
                  <div className="" style={{ position: "relative" }}>
                    Projects
                    <span
                      style={{
                        position: "absolute",
                        right: "-12px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        fontSize: "20px",
                        color: "green",
                        fontWeight: "bold",
                      }}
                    >
                      •
                    </span>
                  </div>
                </Link>
              </li>

              <li className="navbar-item">
                <Link
                  to="/services"
                  className="navbar-link"
                  data-navbar-link
                  style={{ color: `${color}` }}
                >
                  Services
                </Link>
              </li>

              <li className="navbar-item">
                <Link
                  to="/pricing"
                  className="navbar-link"
                  data-navbar-link
                  style={{ color: `${color}` }}
                >
                  Pricing
                </Link>
              </li>
              <li className="navbar-item">
                <Link
                  to="/subscription"
                  className="navbar-link"
                  data-navbar-link
                  style={{
                    color: `${color}`,
                  }}
                >
                  Become Member
                </Link>
              </li>
              <li className="navbar-item">
                <a
                  href="https://calendar.app.google/oZvo3V6bww95hqJ47"
                  target="__blank"
                  className="navbar-link"
                  data-navbar-link
                  style={{
                    color: `${color}`,
                    border: "1px solid",
                    padding: "10px",
                    borderRadius: "3px",
                  }}
                >
                  Book a Meeting
                </a>
              </li>
            </ul>
          </nav>

          <Link to="/pricing" className="btn">
            <IoChevronForward size={20} />
            <span>Get A Quote</span>
          </Link>

          <button
            className="nav-open-btn"
            aria-label="Open Menu"
            onClick={toggleNavbar}
            style={{ color: `${color}` }}
            data-nav-open-btn
          >
            <MdMenuOpen size={34} />
          </button>
        </div>
      </header>
      {/* <!--- #GO TO TOP --> */}

      {isGoTopVisible && (
        <a
          href="#top"
          className="go-top active"
          aria-label="Go To Top"
          data-go-top
        >
          <IoMdArrowRoundUp size={20} />
        </a>
      )}

      {/* <!--- #WHATSAAP --> */}

      <a
        href="https://wa.me/+254791004783"
        target="_blank"
        class="whatsaap active"
        rel="noreferrer"
      >
        <AiOutlineWhatsApp size={20} style={{ margin: 0 }} />
      </a>
    </>
  );
};

export default Header;
